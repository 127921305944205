import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        pic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "date": "2016-11-07",
  "title": "Principles of Strong Components",
  "description": "Make your JavaScript components solid, clean, and highly reusable.",
  "cover": "/img/cover/bricks.jpg",
  "pic": "/img/bricks.jpg",
  "color": "#ce9ca6"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`JavaScript projects start out fun. They begin with a full heart and an empty `}<inlineCode parentName="p">{`index.html`}</inlineCode>{`. But things tend to get messy and unorganized in a hurry. Why does this happen?`}</p>
    <p>{`A codebase is like a tower of bricks. Each meticulously placed and relying on the strength of those beneath it. Each intended to hold the entire weight of the rest of the tower. One of the main reasons rewrites eventually seem inevitable is because `}<strong parentName="p">{`we're not great at building bricks`}</strong>{`.`}</p>
    <p>{`The last couple years have been incredible for the entire web dev community. We're collectively on board with the idea that features should be built as a tree of components. Components are the primary building blocks (bricks) of our applications. They're also a primary indicator of the health of a project. If you have great components, you've got a solid shot at keeping your project clean and simple - a state that `}<em parentName="p">{`helps`}</em>{` you write new features rather than slows you to a crawl. You most likely don't need to lock yourself in a supply closet for months to hack on that (super risky) rewrite. `}<strong parentName="p">{`You probably just need better components`}</strong>{`.`}</p>
    <p>{`Using the `}<inlineCode parentName="p">{`component()`}</inlineCode>{` helper that comes with your preferred UI framework is a great start, but it's not enough. There are `}<strong parentName="p">{`principles`}</strong>{` that if followed can make your components useful, composable, maintainable, and reliable. If written well they can become productivity assets rather than a growing liability. So what makes a component strong?`}</p>
    <p>{`A `}<strong parentName="p">{`strong component:`}</strong></p>
    <h2>{`1. is cut at the right boundaries`}</h2>
    <p>{`A component that tries to do too much is guaranteed to introduce too much complexity. One that does too little is hardly worth importing. Developing an intuition around component boundaries takes some time but is worth practicing. Don't get paralyzed trying to get it perfect though either. It's best to look at the mock/sketch/design and just build it as the first name that comes to mind. If you start feeling friction you can always adjust the boundaries or break it up into disparate components.`}</p>
    <p>{`Some signs the boundary is too broad:`}</p>
    <ul>
      <li parentName="ul">{`You struggle naming it, or its name doesn't really seem to fit what you're using it for`}</li>
      <li parentName="ul">{`It starts taking more than just a few configuration options`}</li>
      <li parentName="ul">{`The component code starts to get large`}</li>
    </ul>
    <h2>{`2. is easy to locate`}</h2>
    <p>{`When inspecting the DOM you should see the components as the elements, rather than a generic tag like `}<inlineCode parentName="p">{`<div/>`}</inlineCode>{` with magic attributes.`}<br parentName="p"></br>{`
`}{`For example you should see `}<inlineCode parentName="p">{`<Playlist/>`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`<div class="playlist" />`}</inlineCode>{`.`}<br parentName="p"></br>{`
`}{`This makes it easy to know which component you need to jump to and edit to make a change.`}<br parentName="p"></br>{`
`}{`React (with dev tools) and Angular 2 do this by default. If you're on Angular 1 you can write `}<a parentName="p" {...{
        "href": "https://docs.angularjs.org/guide/component"
      }}>{`components`}</a>{` for almost everything, and restrict any directives to elements (`}<inlineCode parentName="p">{`restrict: 'E'`}</inlineCode>{`).`}</p>
    <p>{`The component's name should also be fairly easy to guess correctly and find in the project.`}</p>
    <h2>{`3. contains all its parts`}</h2>
    <p>{`A strong component has its HTML and CSS collocated in the same file with its JavaScript. This brings enormous cognitive benefits.`}<br parentName="p"></br>{`
`}{`From the psychological concept of `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Chunking_(psychology)"
      }}>{`chunking`}</a>{` we learn that we can hold up to 6 or 7 related items in our working memory at a time.`}<br parentName="p"></br>{`
`}{`If you have to reason about three different files (plus a directory to hold them) per component you'll hit this limit pretty quick.`}<br parentName="p"></br>{`
`}{`When your component contains all its parts you can more easily load it into memory as a single entity.`}<br parentName="p"></br>{`
`}{`So for example you could work on a `}<inlineCode parentName="p">{`CardDetails`}</inlineCode>{` feature, that contains an `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Tags`}</inlineCode>{`, `}<inlineCode parentName="p">{`Description`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Members`}</inlineCode>{` components all without feeling overwhelmed (or having to open 15 files).`}</p>
    <p>{`There are many additional benefits to this principle:`}</p>
    <ul>
      <li parentName="ul">{`the perceived overhead of creating a new component is a lot smaller, so you're more likely to break up large components into smaller ones.`}</li>
      <li parentName="ul">{`the fact that CSS and HTML count towards your component's lines of code will naturally encourages you to write smaller components.`}</li>
      <li parentName="ul">{`ability to apply tree-shaking to unused components means fewer unused styles on the page`}</li>
      <li parentName="ul">{`flexibility to lazy load components with their styles on demand`}</li>
    </ul>
    <p>{`React already includes its HTML (JSX) in the component file. Angular 1 and 2 can also with `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals"
      }}>{`ES2015 template literals`}</a>{`.`}</p>
    <p>{`For CSS, Angular 2 comes with a great way to do this out of the box. New tools like `}<a parentName="p" {...{
        "href": "https://github.com/Khan/aphrodite"
      }}>{`Aphrodite`}</a>{` make it easy for React/Angular1/Vue etc.`}</p>
    <h2>{`4. avoids side effects`}</h2>
    <p>{`Bugs caused by side effects are some of the hardest to track down. A strong component is good citizens. It avoids modifying state (including global variables) that doesn't belong to it, and avoids writing to or reading from parts of the DOM that are outside of its root element.`}</p>
    <h2>{`5. has few options`}</h2>
    <p>{`A component that needs a lot of configuration options is going to be a pain to maintain and a pain to use.`}<br parentName="p"></br>{`
`}{`Keep the component as simple as you can. Needing a lot of options is usually a sign that you're trying to make your component `}<em parentName="p">{`too`}</em>{` generic.`}<br parentName="p"></br>{`
`}{`Instead, split it up into more specific components. For example instead of having a single `}<inlineCode parentName="p">{`User`}</inlineCode>{` component that tries to represent all the different ways a user can be presented you might be better off with an `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Bio`}</inlineCode>{`, and `}<inlineCode parentName="p">{`UserSummary`}</inlineCode>{` components.`}</p>
    <h2>{`6. is imported and used directly`}</h2>
    <p>{`Strong components don't rely on magic global strings for rendering. What you `}<inlineCode parentName="p">{`import`}</inlineCode>{` is what you drop into your markup.`}<br parentName="p"></br>{`
`}{`This gives your code `}<strong parentName="p">{`traceability`}</strong>{` which is `}<em parentName="p">{`so`}</em>{` critical for a healthy codebase. Without this it's not easy to guess how your app is pieced together. It's also really hard to know for sure if you can delete a component.`}</p>
    <p>{`Rendering what you import is the default behavior in React:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import Playlist from './Playlist';

const MusicPlayer = () => (
 <Playlist/>
);
`}</code></pre>
    <p>{`With Angular you have to be a bit more deliberate about what your components exports, but it's pretty straight forward.`}</p>
    <p>{`For example instead of adding a magic string selector to your template:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`template: \`<app>
            <user-avatar/>
          </app>\`
`}</code></pre>
    <p>{`You'd `}<inlineCode parentName="p">{`import`}</inlineCode>{` the component and include render it directly:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import UserAvatar from './UserAvatar';

template: \`<app>
            <\${UserAvatar}/>
          </app>\`
`}</code></pre>
    <p>{`This is a big improvement for Angular development, where common pitfalls include:`}</p>
    <ul>
      <li parentName="ul">{`forgetting to import the component before trying to use it in a template`}</li>
      <li parentName="ul">{`using something (and it working!) that was randomly imported somewhere else...until someone changes it and breaks your seemingly unrelated code`}</li>
      <li parentName="ul">{`messing up the special snake-case to camel-case magic string conversion`}</li>
    </ul>
    <p>{`Here is a `}<a parentName="p" {...{
        "href": "https://gist.github.com/geddski/02eb46711eda1ee9456fc4aa292c395e"
      }}>{`component helper`}</a>{` that makes this easy in Angular.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`Building strong components takes discipline and practice, but it's worth it. Over time you'll build up a set of very useful components that will help you build features quickly. They'll be self-contained, easy to use, reason about, and maintain. You'll avoid a lot of crazy side effect bugs and won't get confused about how your project is pieced together.`}</p>
    <p>{`Build strong components. Your codebase and your productivity will thank you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      